import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import  { Router } from 'react-router';
import history from './services/history';
import HomePage from './pages/HomePage/homePage'
import AdminPage from './pages/AdminPage/adminPage'
import FAQPage from './pages/FAQPage/faqPage'


function MyRoutes(){

    return(
        <BrowserRouter>
            <Router history={history}>
                <Route path="/" exact component={HomePage}/>
                <Route path="/FAQ" exact component={FAQPage}/>
                <Route path="/admin" exact component={AdminPage}/>
            </Router>
        </BrowserRouter>
    );
}

export default MyRoutes;
