//import React, { useEffect } from 'react';
import Routes from './routes';
//import api from './services/api';


function App() {
    return  <Routes/>
}

export default App;
