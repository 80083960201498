import styled from 'styled-components';



export const BodyHeader = styled.div`
  margin: 1%;
  padding: 1%;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: grid;
  flex-direction: row;
  box-sizing: border-box;
  grid-template-columns: 10px 250px;
  justify-content: space-between;
`

export const DivRightHeader = styled.div`
  margin: 5%;
  padding: 1%;
  width:90%;
  height: 10px;
  max-width: 10px;
  margin: 0 auto;
  display: grid;
  flex-direction: column;
  box-sizing: border-box;
`
