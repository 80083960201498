import React from 'react';

import { BodyLogo } from './stylesBodyLogo';

export function FullLogo() {

    return (
        <>
          <BodyLogo/>
        </>
    )
}

