import { createGlobalStyle } from "styled-components";

const globalStyle = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
  }

:root {
  --whitesoft:#f8f8f8;
  --white: #e0e5ec;
  --black: #000000;
  --gray: #5a5a5a;
  --softPink:#edbed0;
  --pink:#AC49BC;
  --purple:#64418c;
  --green:#01410d;
  --yellow:#FFD966;
}

h2,
strong {
  margin: 1%;
  padding: 1%;
  padding-top: 10%;
  position: center;
  align-items: center;
  font-weight: 500;
  text-justify: center;
  text-align: center;
  border-radius: 10px;
  background: transparent;
  color: var(--black);
}

h1,
h3,
h4,
h5,
h6,
strong {
  margin: 1%;
  padding: 10px;
  justify-content: center;
  position: center;
  align-items: center;
  font-weight: 500;
  text-justify: center;
  text-align: center;
  border-radius: 10px;
  background: transparent;
  color: var(--white);
}

`
export default globalStyle
