import React from 'react';

import { ContainerLeft, ContainerCenter, ContainerRight} from '../../../styles/styles'
import { BodyStyle } from './stylesBody'
import { Header } from '../header/header'
import { FullLogo } from '../body/bodyLogo/BodyLogo'
import { BoxTop } from './boxTop/Box'
import { BoxCenter } from './boxCenter/Box'
import { BoxDown } from './boxDown/Box'
import { BoxFooter } from './boxFooter/Box'
import { CenterPicture } from './centerPicture/CenterPicture'
import { Footer } from '../footer/footer'
import { EmphasisPhrase } from './emphasisPhrase/emphasisPhrase'



export function Body() {

    return (
          <BodyStyle>

          <ContainerLeft/>
          <ContainerCenter>
            <Header/>
            <FullLogo/>
            <EmphasisPhrase/>
            <BoxTop/>
            <h2> A combinação perfeita entre conforto e elegância</h2>
            <BoxDown/>
            <h2> Mulher forte é aquela que encara o mundo e sabe o poder que tem.</h2>
            <BoxFooter/>
            <CenterPicture/>
            <h5>EHVA é ir além do óbvio, é encarar os desafios da vida com ousadia, fé e flexibilidade.</h5>
            <BoxCenter/>
            <Footer/>
          </ContainerCenter>
          <ContainerRight/>

          </BodyStyle>
    )
}
