import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

export function WhatsWidget() {

    return (
        <>
          <WhatsAppWidget
            phoneNo="+5547999769982"
            position="right"
            widgetWidth="270px"
            widgetWidthMobile="270px"
            autoOpen={true}
            autoOpenTimer={12000}
            messageBox={true}
            messageBoxTxt="Sim 🤗, "
            iconSize="75"
            iconColor="#01410d"
            iconBgColor="#f8f8f8"
            headerIcon="https://s3.amazonaws.com/appforest_uf/f1672881716685x239204124134503970/EVAH_logo.png"
            headerIconColor="#01410d"
            headerTxtColor="#f8f8f8"
            headerBgColor="#AC49BC"
            headerTitle="EHVA"
            headerCaption="Online"
            bodyBgColor="#f8f8f8"
            chatPersonName="EHVA"
            chatMessage={<>Oláá 👋 <br /><br /> Posso te ajudar ?</>}
            footerBgColor="#AC49BC"
            btnBgColor="#01410d"
            btnTxt="Iniciar Conversa"
            btnTxtColor="#e0e5ec"
          />
        </>
    )
}
