import React from 'react';

import { StyleFooterLogo } from '../footerLogo/stylesFooterLogo'

export function FooterLogo() {

    return (
        <>
            <StyleFooterLogo/>
        </>


    )
}

