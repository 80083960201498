import styled, { keyframes } from 'styled-components';

const clarity = keyframes`

  0% {
    color: var(--pink);
    text-shadow: 0 0 20px var(--pink),
    0 0 25px var(--softPink),
    0 0 30px var(--purple);
  }

  1%, 90% {
    color: var(--pink);
  }
`

export const Shine = styled.h6`
  padding: 30px;
  font-size: 40px;
  animation: ${clarity} 5s infinite;
  animation-timing-function: ease-out;
  color: var(--pink);
`
