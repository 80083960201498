import styled from 'styled-components';

export const BodyFooter = styled.div`
  margin: 1%;
  padding: 10% 5% 33% 5%;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: grid;
  flex-direction: row;
  grid-template-columns: 180px 250px;
  justify-content: space-between;
  box-sizing: border-box;
`
export const DivLeftFooter = styled.div`
  margin: 1%;
  padding: 1%;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  display: grid;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
`
export const DivRightFooter = styled.div`
  margin: 1%;
  padding: 1%;
  width:90%;
  max-width: 280px;
  margin: 0 auto;
  display: grid;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
`

