import React from 'react';

import { BoxStyle } from './stylesBox';
import { Retangle } from './retangle/Retangle'

export function BoxTop() {

    return (
        <>
          <BoxStyle>  <Retangle/>  </BoxStyle>
        </>
    )
}

