import styled from 'styled-components';

export const DivSocialMedia = styled.div`
  height: 90px;
  padding: 60px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: grid;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  grid-template-columns: 40px 40px 40px 40px 40px 40px 40px;
  filter: drop-shadow(0 2px 1px var(--pink))
`
