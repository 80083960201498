import React from 'react'

import { Logo } from '../../logo/Logo'
import { BodyHeader, DivRightHeader} from './stylesHeader'

export function Header() {

  return(
    <BodyHeader>
      <Logo/>
      <DivRightHeader/>

    </BodyHeader>
  )
}
