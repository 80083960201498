import React from 'react';

import { StyleLogo } from '../logo/stylesLogo'

export function Logo() {

    return (
        <>
            <StyleLogo/>
        </>


    )
}

