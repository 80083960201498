import styled from 'styled-components';


export const BodyStyle = styled.div`

margin: 0 auto;
display: flex;
box-sizing: border-box;
flex-direction: row;
background: linear-gradient(
    #e0e5ec, var(--black) 93%
  );`
