//import history from '../services/history'

import React from 'react'
import { Button } from '../../styles/styles'

export default function AdminPage() {



  return (

    <>

      <Button>Click</Button>

    </>

  )
}
