import styled from 'styled-components';
import Image from '../../../../../../assets/images/ModareTenis.png'

export const RetangleImageStyle = styled.div`

  height: 100%;
  width: 82% auto;
  background: url(${Image}) no-repeat center;
  background-position: center;
  background-size: cover ;
  box-sizing: border-box;
  filter: drop-shadow(10px 0 30px var(--pink))
`
