import styled from 'styled-components';


export const BoxStyle = styled.div`

  margin: 0 auto;
  padding: 1%;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-grow: 0;

  text-justify: center;
  text-align: center;
`

/*
// Bloob background

const image = 'data:image/svg+xml;utf8,%3Csvg width=%22697.294%22 height=%22665.735%22 viewBox=%22203.261 158.09 697.294 665.735%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill="#01410d" d=%22M832 665.5Q691 831 504.5 823.5T244.5 658q-73.5-158-16-343.5t252-151Q675 198 824 349t8 316.5Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23444cf7%22 d=%22M832 665.5Q691 831 504.5 823.5T244.5 658q-73.5-158-16-343.5t252-151Q675 198 824 349t8 316.5Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E'



export const BoxStyle = styled.div`

  margin: 0 auto;
  padding: 1%;
  box-sizing: border-box;
  flex-direction: row;

  align-items: center;
  display: flex;
  flex-grow: 0;
  background-image: linear-gradient(90deg, #01410d 0%, #AC49BC 100%);
  border-radius: 30% 70% 31% 69% / 18% 25% 72% 82%;
  width: 100%;
  height: 365.7353515625px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${image});
  text-justify: center;
  text-align: center;
  &:first-child {
  align-content: center;
}

`
*/
