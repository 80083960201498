import React from 'react';

import { RetangleImageStyle } from './stylesRetangleImage';
//import { BoxImage } from '../boxImage/BoxImage';

export function RetangleImage() {

    return (
        <>
          <RetangleImageStyle/>
        </>
    )
}
