import styled from 'styled-components';

const image = 'https://s3.amazonaws.com/appforest_uf/f1673307276667x160148368881615870/PersonScarpin.jpeg'

export const Picture = styled.div`

  margin: 0 auto;
  padding: 1%;
  height: 1400px;
  background: url(${image}) no-repeat center;

  width: 82%;
  max-width: 1024px;
  box-sizing: border-box;
  border-radius: 10px;
`
