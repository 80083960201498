import React from 'react';

import { RetangleStyle } from './stylesRetangle';
import { RetangleImage } from './retangleImage/RetangleImage';

export function Retangle() {

    return (
        <>
          <RetangleStyle> <RetangleImage/>  </RetangleStyle>
        </>
    )
}
