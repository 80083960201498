import React from 'react';

import { DivSocialMedia } from './stylesSocialMedia';
import TikTok from '../../../../assets/Icons/tiktok.svg'
import Instagram from '../../../../assets/Icons/instagram.svg'
import Facebook from '../../../../assets/Icons/facebook.svg'
import Pinterest from '../../../../assets/Icons/pinterest.svg'
import WhatsApp from '../../../../assets/Icons/whats.svg'
import Email from '../../../../assets/Icons/email.svg'
import Maps from '../../../../assets/Icons/maps.svg'


export function SocialMedia() {

    return (
        <>
          <DivSocialMedia>
          <a href="https://www.facebook.com/profile.php?id=100073382680256" title="Facebook">  <img src={Facebook}  alt="Facebook" /> </a>
          <a href="https://www.tiktok.com/@useehva" title="TikTok"> <img src={TikTok} alt="TikTok" />  </a>
          <a href="https://www.instagram.com/useehva/" title="Instagram">  <img src={Instagram}  alt="Instagram" /> </a>
          <a href="https://br.pinterest.com/ehvastore/" title="Pinterest">  <img src={Pinterest}  alt="Pinterest" /> </a>
          <a href="https://wa.me/+5547999769982" title="Whatsapp">  <img src={WhatsApp}  alt="Whatsapp" /> </a>
          <a href="mailto:useehva@gmail.com" title="Email">  <img src={Email}  alt="Email" /> </a>
          <a href="https://goo.gl/maps/5Cus4R6WthTpC4qM6" title="Localização">  <img src={Maps}  alt="Localização" /> </a>
          </DivSocialMedia>
        </>
    )
}
