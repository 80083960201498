import styled from 'styled-components';

export const RetangleStyle = styled.div`

  margin: 0 auto;
  width: 420px;
  height: 340px;
  box-sizing: border-box;

`

/*
  // Glass Box

  export const RetangleStyle = styled.div`

  margin: 0 auto;
  width: 420px;
  height: 340px;
  box-sizing: border-box;
  border-radius: 10px;
  background: rgba(255,255,255,0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255,255,255,0.25);
`

 */
