import styled from 'styled-components';
import whiteLogo from '../../assets/images/whiteLogo.png'

export const StyleFooterLogo = styled.div`

    margin: 0 auto;
    padding: 1%;
    top: 6%;
    left: 1%;
    height: 5em;
    width: 5em;
    background: var(--black);
    background-image: url(${whiteLogo});
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 5px 5px 10px var(--pink), -5px -5px 10px var(--pink);
    border-radius: 50%;


`
