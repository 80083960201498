//import history from '../services/history'
import { Body }  from '../../components/homePage/body/body'
import React from 'react'
// import Crud from '../components/crud/crud'
import { WhatsWidget }  from '../../components/whatsWidget/WhatsWidget'

export default function Main() {

  return (
    <>
    <Body/>
    <WhatsWidget/>
    </>
  )
}
