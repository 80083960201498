import styled from 'styled-components';

export const ContainerLeft = styled.div`

  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background: transparent;
`
export const ContainerCenter = styled.div`

  margin: 1%;
  width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background: linear-gradient(
    #e0e5ec, var(--black) 93%
  );
`
//
/**
  background: rgba(255,255,255,0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255,255,255,0.25);
*/


export const ContainerRight = styled.div`
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background: transparent;
`

export const Button = styled.button`
background: #000000;
border-radius: 10px;
width: 342px;
height: 64px;
color: ${props =>props.isOn ? '#ffffff' : '#000000'};
border: none;
font-size: 19px;
cursor: pointer;
`
