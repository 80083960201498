import React from 'react'

import { SocialMedia } from './socialMedia/SocialMedia'
import { FooterLogo } from '../../footerLogo/FooterLogo'
import { BodyFooter, DivLeftFooter, DivRightFooter } from './stylesFooter'

export function Footer() {

  return(
    <>

      <SocialMedia/>
      <h5>Mídias Sociais</h5>
      <BodyFooter>
        <DivLeftFooter>
          <FooterLogo/>
          <h6>® 2018 EHVA All rights reserved.</h6>
          <h6> Este website está parecendo muito simples?  PERFEITO!  </h6>
          <h6> É esse o nosso objetivo: apresentar um conceito minimalista evitando poluição visual, você vai adorar tudo que a EHVA vai colocar a sua disposição, tem muita coisa em nosso roadmap. 🤭    </h6>
        </DivLeftFooter>
        <DivRightFooter>
          <h6> Avenida Central, 362, Centro, Balneário Camboriú, SC - 88330-668 </h6>
          <h6> CNPJ: 32.429.360/0001-08 </h6>
          <h6> 47 99976-9982 </h6>
        </DivRightFooter>

      </BodyFooter>
      </>
  )
}


