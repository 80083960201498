//import history from '../services/history'

import React from 'react'

export default function FAQPage() {



  return (

    <>
      <h1> Olá </h1>

    <h2> Procurando por calçados femininos elegantes e empoderadores?  </h2>
      <h3> A loja EHVA tem tudo o que você precisa! Confira nossa seleção de calçados femininos e encontre o par perfeito para o seu estilo e empoderamento. </h3>

    <h2> A loja EHVA Calçados é o lugar perfeito para encontrar o seu par ideal de calçados femininos. </h2>
      <h3> Com uma ampla seleção de estilos, designs e tamanhos, temos algo para todos os gostos e necessidades. </h3>
      <h3> Nós acreditamos que os calçados femininos não são apenas acessórios para os pés, mas também uma forma de expressar sua personalidade, estilo e empoderamento. </h3>
      <h3> Nós nos preocupamos com a qualidade e conforto dos nossos calçados femininos,por isso, trabalhamos com marcas renomadas e de alta qualidade, selecionando cuidadosamente cada modelo para garantir o máximo de conforto, durabilidade e estilo.  </h3>
      <h3>  Além disso, nossa equipe de especialistas em moda está sempre pronta para ajudá-lo a escolher o par perfeito para você. </h3>
      <h3>  Um dos nossos principais objetivos na EHVA é fornecer às mulheres uma variedade de opções de calçados femininos que ajudem a aumentar sua autoestima e empoderamento. Sabemos que o calçado pode ser uma ferramenta poderosa para elevar a confiança e a autoestima das mulheres, por isso, escolhemos cuidadosamente modelos que são confortáveis, estilosos e empoderadores. </h3>
      <h3>  Nós acreditamos que a moda não deve ser limitada por tamanhos ou estilos, por isso, oferecemos uma ampla variedade de opções de calçados femininos em tamanhos e modelos diversos. </h3>
      <h3>  Se você está procurando por um calçado feminino clássico e elegante para o trabalho, ou um modelo moderno e descolado para um passeio no fim de semana, nós temos um perfeito para você. </h3>

    <h2> Na EHVA, acreditamos que a moda não é apenas uma forma de se expressar, mas também uma forma de empoderamento feminino. </h2>
      <h3> Estamos comprometidos em fornecer aos nossos clientes os melhores modelos de calçados femininos que atendam às suas necessidades e desejos.   </h3>
      <h3> Então, visite-nos hoje mesmo e encontre o seu par ideal </h3>

    <h2> Conheça a nossa coleção de calçados femininos na EHVA Calçados, que inclui modelos para todas as ocasiões e estilos.   </h2>
      <h3> Temos opções elegantes para o escritório, como scarpins e sapatos de salto alto, bem como opções mais casuais, como tênis, sapatilhas e sandálias. </h3>
      <h3> Também oferecemos uma variedade de opções de cores e materiais, desde couro até tecidos e sintéticos confira nossos modelos personalizados, onde você pode escolher entre uma variedade de materiais. </h3>
      <h3> Além disso, na EHVA Calçados, acreditamos que o conforto é tão importante quanto o estilo. Por isso, nossos modelos de calçados femininos são projetados para proporcionar o máximo de conforto, independentemente da ocasião. Desde palmilhas acolchoadas até solas antiderrapantes, cuidamos de cada detalhe para garantir que você possa usar seus calçados femininos com conforto durante todo o dia. </h3>
      <h3> E não se esqueça, na EHVA, também estamos comprometidos com o empoderamento feminino. Acreditamos que os calçados femininos podem ser uma ferramenta poderosa para ajudar as mulheres a se sentirem confiantes e empoderadas, por isso, selecionamos modelos que não apenas são elegantes e confortáveis, mas também ajudam a transmitir uma sensação de poder e confiança. </h3>
      <h3> Visite a loja EHVA Calçados hoje mesmo e experimente nossa coleção de estação, com certeza você encontrará o par ideal para complementar o seu estilo! </h3>

    <h2> Os calçados são uma peça fundamental para a moda feminina, não apenas como um item de vestuário, mas como uma forma de expressão pessoal. </h2>
      <h3> Na loja EHVA, você encontrará uma seleção exclusiva de calçados femininos para todas as ocasiões, desde saltos elegantes até tênis confortáveis. </h3>
      <h3> Na EHVA nossa amável equipe pode ajudá-la a encontrar o par ideal para seus pés </h3>

    <h2>  A loja EHVA é conhecida por sua seleção linda de calçados  que combinam elegância e conforto. </h2>
      <h3>  Calçados de Salto Alto: Os saltos são um clássico da moda feminina, trazendo elegância e confiança a qualquer look. Na loja EHVA, temos uma variedade de saltos, desde os modelos clássicos até os mais modernos, para que você possa encontrar o par perfeito para a sua personalidade. </h3>
      <h3>  Calçados de Plataforma: Se você quer adicionar altura sem sacrificar o conforto, os calçados de plataforma são uma ótima opção. A loja EHVA tem uma seleção de plataformas elegantes e modernas que combinam estilo e praticidade. </h3>
      <h3>  Calçados Esportivos: Para mulheres que levam um estilo de vida ativo, a loja EHVA tem uma seleção de tênis e calçados esportivos que combinam conforto e estilo. Desde corrida até treinamento, nossos calçados esportivos são perfeitos para uma rotina de exercícios diários. </h3>
      <h3>  Calçados Casuais: Se você está procurando por um calçado confortável e casual, a loja EHVA tem uma variedade de opções elegantes para atender às suas necessidades. Desde sandálias até sapatilhas, nossos calçados casuais são perfeitos para um dia relaxante ou para um passeio descontraído. </h3>
      <h3>  Calçados Elegantes: Além de elegantes e confortáveis, os calçados femininos da loja EHVA também são empoderadores. Acreditamos que a moda pode ser uma forma de expressão pessoal e empoderamento, e nossos calçados femininos são projetados para fazer você se sentir confiante e forte. </h3>

  <h1> FAQ </h1>

    <h2> Qual é o estilo de calçados femininos que a loja EHVA oferece?     </h2>
      <h3> A loja EHVA oferece uma ampla seleção de estilos de calçados femininos, incluindo saltos altos, sandálias, tênis, botas e muito mais. Nós temos algo para todos os gostos e estilos, desde os mais clássicos até os mais ousados. </h3>

    <h2>  A loja EHVA tem opções de calçados femininos confortáveis?  </h2>
      <h3>  Sim, a EHVA tem uma seleção de calçados femininos confortáveis ​​que são ideais para uso diário.  </h3>
      <h3>  Nós acreditamos que é possível ter estilo e conforto ao mesmo tempo, por isso escolhemos cuidadosamente cada modelo em nossa loja para garantir que eles sejam ambos.</h3>

    <h2>  A loja EHVA oferece entrega em domicílio?  </h2>
      <h3>  Sim, oferecemos entrega em domicílio para todo o Brasil. Verifique as opções de frete disponíveis no momento da compra em nosso site.</h3>

    <h2>  A EHVA Calçados tem opções de calçados femininos empoderadores?  </h2>
      <h3>  Sim, nossa loja tem uma variedade de modelos de calçados femininos que ajudam a empoderar as mulheres, tornando-as mais confiantes e seguras de si.</h3>
      <h3>  Temos desde calçados com design arrojado até modelos clássicos com toques modernos.</h3>

    <h2>  Se você está procurando calçados femininos de alta qualidade, confortáveis, elegantes e empoderadores, a EHVA é a escolha ideal. </h2>
      <h3>  Nós temos uma seleção exclusiva de calçados femininos que são cuidadosamente selecionados para atender aos gostos de nossos clientes. </h3>
      <h3> Além disso, nossa política de troca e devolução garante que você fique satisfeito com sua compra. </h3>

    <h2>  Visite nossa loja física na linda cidade de Balneário Camboriú e experimente nossos modelos incríveis de calçados femininos. </h2>

    <h1> Gratidão </h1>

    </>
  )
}
