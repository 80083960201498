import React from 'react'

import { Picture } from '../centerPicture/stylesCenterPicture'

export function CenterPicture() {

  return(
    <>
      <Picture/>
    </>
  )
}
