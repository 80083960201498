import styled from 'styled-components';
import blackLogo from '../../../../assets/images/Logo.png'

export const BodyLogo = styled.div`
  margin: 0 auto;
  width: 70%;
  padding-top: 15%;
  background: url(${blackLogo}) no-repeat center;
  background-position: center;
  background-size: contain;
  box-sizing: border-box;
`
